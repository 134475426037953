@import "projects/common-module/src/lib/app-styles/common-variables";

$width-basic: 320px;
$width-card-user: 700px;
$width-form-calibration: 120px;
$width-form-field: 250px;
$width-calc: 300px;
$width-calc-first-col: 90px;
$width-calc-second-col: 160px;
$width-tab: 220px;

$height-header: 60px;
$height-filter-group: 57px;
$height-card: 340px;
$height-card-user: 380px;
$height-table-unit-details: 280px;
$height-option: 40px;
$height-field-calibration-form: 30px;
$height-box-buttons: 55px;

$size-calculator-button: 35px;

$color-primary-default: #386dac;
$color-primary-blue: #002395;
$color-primary-red: #b63134;
$color-primary-purple: #7B1FA2;
$color-primary-green: #00796B;
$color-primary-gray: #455A64;
$color-primary-black: #000000;
