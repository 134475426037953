.background-primary {
  background-color: var(--color-primary);
}

.background-accent {
  background-color: var(--color-accent);
}

.background-white {
  background-color: var(--color-white);
}
