/*общая ширина скроллбара:*/
::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/*оформление полосы прокрутки*/
::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
  border-color: #777777;
  background-color: #aaaaaa;
}

/*При наведении курсора на полосу прокрутки*/
::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  border-color: #555555;
  background-color: #777777;
}

/* ползунок */
::-webkit-scrollbar-track {
  border-width: 0;
}

/*При наведении курсора на ползунок*/
::-webkit-scrollbar-track:hover {
  border-left: solid 1px #aaaaaa;
  background-color: #eeeeee;
}
