.flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.flex1 {
  flex: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-gap4 {
  gap: 4px;
}

.flex-gap8 {
  gap: 8px;
}


.flex-gap12 {
  gap: 12px;
}

.flex-gap16 {
  gap: 16px;
}

.flex-gap20 {
  gap: 20px;
}

.flex-v-center {
  align-items: center;
}

.flex-v-baseline {
  align-items: baseline;
}

.flex-v-start {
  align-items: flex-start;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}
