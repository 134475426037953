.theme-DEFAULT {
  --color-primary: #386dac; //mat dark primary
  --color-basic: #407ac1; //mat primary
  --color-accent: #FF4081; //mat accent
  --color-minor: #c5cae9; // mat light primary
  --color-text: #212121; //mat primary text
  --color-secondary: #757575; //mat secondary
  --color-divider: #BDBDBD; // mat divider
  --color-light-opacity: rgba(230, 230, 230, 0.85);
  --color-almost-white: #f0f0f0;
  --color-white: #ffffff;
  --color-green: forestgreen;
  --color-red: red;
  --color-yellow: #ffcc33;
  --color-orange: #ffa500;
  --color-map-basic: #2b8cf7;
}

.theme-BLUE {
  --color-primary: #002395; //mat dark primary
  --color-basic: #002395; //mat primary
  --color-accent: #F8D030; //mat accent
  --color-minor: #CFD8DC; // mat light primary
  --color-text: #212121; //mat primary text
  --color-secondary: #757575; //mat secondary
  --color-divider: #BDBDBD; // mat divider
  --color-light-opacity: rgba(230, 230, 230, 0.85);
  --color-almost-white: #f0f0f0;
  --color-white: #ffffff;
  --color-green: forestgreen;
  --color-red: red;
  --color-yellow: #ffcc33;
  --color-orange: #ffa500;
  --color-map-basic: #2b8cf7;
}

.theme-RED {
  --color-primary: #b63134; //mat dark primary
  --color-basic: #E03D41; //mat primary
  --color-accent: #FF9800; //mat accent
  --color-minor: #e5c0c1; // mat light primary
  --color-text: #212121; //mat primary text
  --color-secondary: #757575; //mat secondary
  --color-divider: #BDBDBD; // mat divider
  --color-light-opacity: rgba(230, 230, 230, 0.85);
  --color-almost-white: #f0f0f0;
  --color-white: #ffffff;
  --color-green: forestgreen;
  --color-red: red;
  --color-yellow: #ffcc33;
  --color-orange: #ffa500;
  --color-map-basic: #2b8cf7;
}

.theme-PURPLE {
  --color-primary: #7B1FA2; //mat dark primary
  --color-basic: #9C27B0; //mat primary
  --color-accent: #E040FB; //mat accent
  --color-minor: #E1BEE7; // mat light primary
  --color-text: #212121; //mat primary text
  --color-secondary: #757575; //mat secondary
  --color-divider: #BDBDBD; // mat divider
  --color-light-opacity: rgba(230, 230, 230, 0.85);
  --color-almost-white: #f0f0f0;
  --color-white: #ffffff;
  --color-green: forestgreen;
  --color-red: red;
  --color-yellow: #ffcc33;
  --color-orange: #ffa500;
  --color-map-basic: #2b8cf7;
}

.theme-GREEN {
  --color-primary: #00796B; //mat dark primary
  --color-basic: #009688; //mat primary
  --color-accent: #03A9F4; //mat accent
  --color-minor: #B2DFDB; // mat light primary
  --color-text: #212121; //mat primary text
  --color-secondary: #757575; //mat secondary
  --color-divider: #BDBDBD; // mat divider
  --color-light-opacity: rgba(230, 230, 230, 0.85);
  --color-almost-white: #f0f0f0;
  --color-white: #ffffff;
  --color-green: forestgreen;
  --color-red: red;
  --color-yellow: #ffcc33;
  --color-orange: #ffa500;
  --color-map-basic: #2b8cf7;
}

.theme-GRAY {
  --color-primary: #455A64; //mat dark primary
  --color-basic: #607D8B; //mat primary
  --color-accent: #ff5252; //mat accent
  --color-minor: #CFD8DC; // mat light primary
  --color-text: #212121; //mat primary text
  --color-secondary: #757575; //mat secondary
  --color-divider: #BDBDBD; // mat divider
  --color-light-opacity: rgba(230, 230, 230, 0.85);
  --color-almost-white: #f0f0f0;
  --color-white: #ffffff;
  --color-green: forestgreen;
  --color-red: red;
  --color-yellow: #ffcc33;
  --color-orange: #ffa500;
  --color-map-basic: #2b8cf7;
}

.theme-BLACK {
  --color-primary: #000000; //mat dark primary
  --color-basic: #000000; //mat primary
  --color-accent: #da291c; //mat accent
  --color-minor: #dda4a0; // mat light primary
  --color-text: #212121; //mat primary text
  --color-secondary: #757575; //mat secondary
  --color-divider: #BDBDBD; // mat divider
  --color-light-opacity: rgba(230, 230, 230, 0.85);
  --color-almost-white: #f0f0f0;
  --color-white: #ffffff;
  --color-green: forestgreen;
  --color-red: red;
  --color-yellow: #ffcc33;
  --color-orange: #ffa500;
  --color-map-basic: #2b8cf7;
}
