@import "common-variables";
@import 'ngx-aur-mat-dialog/styles/aur-dialog-style.css';

//app-dialog
//Необходимо повесить на div внутри appDialogContent для правильного расчета высоты элементов
.app-dialog-content-column {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-family;
}

.app-dialog-actions {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  gap: 8px;
}
