.padding-0 {
  padding: 0;
}

.padding-4 {
  padding: 4px;
}

.padding-8 {
  padding: 8px;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-4 {
  padding-top: 4px;
}

.padding-top-8 {
  padding-top: 8px;
}

.margin-0 {
  margin: 0;
}

.margin-4 {
  margin: 4px;
}

.margin-8 {
  margin: 8px;
}

.margin-12 {
  margin: 12px;
}

.margin-16 {
  margin: 16px;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-12 {
  margin-left: 12px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-28 {
  margin-top: 28px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}
