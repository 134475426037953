@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/images/icons/iconfont/material-icons.css";
@import "variables";
@import "table";
@import "projects/common-module/src/lib/app-styles/common";
@import "projects/common-module/src/lib/app-styles/buttons";
@import "projects/common-module/src/lib/app-styles/theming";
@import "projects/common-module/src/lib/app-styles/backgrounds";
@import "projects/common-module/src/lib/app-styles/spacing";
@import "projects/common-module/src/lib/app-styles/borders";
@import "projects/common-module/src/lib/app-styles/texts";
@import "projects/common-module/src/lib/app-styles/flexes";
@import "projects/common-module/src/lib/app-styles/cursors";
@import "projects/common-module/src/lib/app-styles/opacities";
@import "projects/common-module/src/lib/app-styles/app-dialog";
@import "projects/common-module/src/lib/app-styles/forms";
@import "projects/common-module/src/lib/app-styles/fonts";
@import "projects/common-module/src/lib/app-styles/chips";
@import "projects/common-module/src/lib/app-styles/tabs";

html {
  position: relative;
  height: 100%;
  font-size: 10px;
  font-family: $font-family;
}

body {
  height: 100%;
  margin: 0;
}

.container-card { // common class for components
  max-width: $width-card;
  height: 100%;

  &.grow-width {
    width: calc(100vw - #{$width-tab} - 5 * 10px);
    max-width: calc(100vw - #{$width-tab} - 5 * 10px);
  }

  &.grow-width-dialog {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
  }

  .parameters {
    padding: 0 16px;

    p {
      margin: 5px;
      line-height: 1.7em;
    }

    .title {
      margin-right: 4px;
      font-size: 1.6rem;
      color: var(--color-secondary);
    }

    .measure {
      margin: 0 3px;
    }
  }
}

.box-header { // common class for components
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $height-header - 10px;
  font-family: $font-family;
  font-size: 1.8rem;
  font-weight: bold;

  .header-icon {
    width: auto;
    min-width: 0;
    height: auto;
    margin-left: 5px;
    line-height: normal;
  }

  .templates-button-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.main-button-color { // common class for components
  color: var(--color-primary);

  &:disabled {
    color: var(--color-minor);
  }
}

.delete-button-color { // common class for components
  color: var(--color-accent);
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.container-modes .modes-list div.mat-list-item-content { // for mechanism-edit
  width: 100%;
  padding: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.container-calibration .form-item .mat-form-field-flex {
  width: $width-form-calibration;
}

/** filter user and unit-list tables */
.wrapper-input .mat-mdc-input-element {
  color: var(--color-accent);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.wrapper-input.mat-focused .mat-form-field-label {
  color: var(--color-accent);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.wrapper-input.mat-focused .mat-form-field-ripple { // change color of underline when focused
  background-color: var(--color-accent);
}

/* end filter user and unit-list tables **/

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.toggle-group-buttons .mat-button-toggle-appearance-standard .mat-button-toggle-label-content { // for header-buttons
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  line-height: normal;
}

/* styles for sensor-calculator */
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-mdc-autocomplete-panel.autocomplete-custom {
  top: 25px; // offset position if panel below form-field for show validating errors
  height: calc(50vh - 30px);
  max-height: none;
  overflow: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-mdc-autocomplete-panel-above {
  /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
  .mat-mdc-autocomplete-panel.autocomplete-custom {
    top: 0; // default position if panel above form-field
    height: 50vh;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.min-button span.mat-button-wrapper {
  padding: 0;
  line-height: $size-calculator-button;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.form-field.search .mat-form-field-wrapper {
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.form-field.add-parameter .mat-focused .mat-form-field-label {
  color: var(--color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.form-field.add-parameter .mat-form-field-underline {
  background-color: var(--color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.form-field.add-parameter .mat-form-field-ripple {
  background-color: var(--color-primary);
}

/* end styles for sensor-calculator*/

.mat-stepper-horizontal .mat-horizontal-content-container {
  padding: 0; // for all stepper
}

.mat-stepper-horizontal .mat-horizontal-stepper-header {
  height: 50px; // for all stepper
}

.mat-mdc-select.hide-select { // unit-messages - hide view mat-select
  width: 0;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow {
    border: none;
  }
}

.mat-sort-header-container {
  border-bottom: none !important; // for change default Angular material styles
}

.item-list .cdk-virtual-scroll-content-wrapper { // for import-from-unit-dialog
  max-width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.item-list.mat-selection-list .mat-list-option.mat-mdc-list-item .mat-list-text { // for import-from-unit-dialog
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mat-mdc-dialog-container .picker-in-separate-window div.md-drppicker.shown { // for edit retranslator item
  left: 50% !important;
  top: 0;
  transform: translate(-50%, -50%);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.unit-data.mat-form-field-appearance-outline .mat-form-field-infix { // for add units dialog
  padding: 8px 0 5px 0;
  border-top: none;
}

.width-100 {
  width: 100%;
}
