//for mat-button
.app-button {
  min-width: auto;
  margin: 0;
  border: 1px solid var(--color-light-opacity);

  &.mat-mdc-button.stroked {
    border: var(--color-light-opacity) solid 1.5px;

    &.white-primary {
      background-color: var(--color-white);
      color: var(--color-primary)
    }

    &.primary {
      color: var(--color-primary)
    }

    &.red {
      color: var(--color-red)
    }
  }

  &.mat-mdc-button.stroked:disabled {
    border: var(--color-light-opacity) solid 1px;
    color: var(--color-divider);
  }

  &.mat-mdc-button.primary:disabled,
  &.mat-mdc-button.delete:disabled {
    background-color: var(--color-light-opacity);
    color: var(--color-divider);
  }

  &.mat-mdc-button.primary {
    color: var(--color-white);
    background-color: var(--color-primary);
  }

  &.mat-mdc-button.accent {
    color: var(--color-white);
    background-color: var(--color-accent);
  }

  &.mat-mdc-button.delete {
    color: var(--color-white);
    background-color: var(--color-red);
  }

  &.mat-mdc-button.red {
    color: var(--color-white);
    background-color: var(--color-red);
  }

  &.mat-mdc-button.green {
    color: var(--color-white);
    background-color: var(--color-green);
  }

  &.mat-mdc-button.orange {
    color: var(--color-white);
    background-color: var(--color-orange);
  }

  // для маленьких кнопок где icon обрезанный
  &.mat-mdc-button.icon-clear-margin > .mat-icon {
    margin-right: 0 !important;
  }

  &.overflow {
    overflow: hidden !important;
  }

  &.align-left {
    justify-content: flex-start;
  }
}

//for mat-icon-button
.app-icon-button {
  &.mat-mdc-icon-button.primary {
    color: var(--color-primary);
  }

  &.mat-mdc-icon-button.accent {
    color: var(--color-accent);
  }

  &.mat-mdc-icon-button.red {
    color: var(--color-red);
  }

  &.mat-mdc-icon-button.green {
    color: var(--color-green);
  }

  &.mat-mdc-icon-button.orange {
    color: var(--color-orange);
  }

  &.mat-mdc-icon-button.black {
    color: var(--color-black);
  }


  &.mat-mdc-icon-button.white {
    color: var(--color-white);
  }

  &.mat-mdc-icon-button.primary:disabled,
  &.mat-mdc-icon-button.accent:disabled,
  &.mat-mdc-icon-button.delete:disabled,
  &.mat-mdc-icon-button.red:disabled {
    color: var(--color-divider);
  }
}

//for fab-button
.app-fab-button {
  &.mdc-fab.primary {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  &.mdc-fab.accent {
    background-color: var(--color-accent);
    color: var(--color-white);
  }
}

.mat-mdc-button > .mat-icon, .mat-mdc-unelevated-button > .mat-icon, .mat-mdc-raised-button > .mat-icon, .mat-mdc-outlined-button > .mat-icon {
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 2.4rem !important;
  height: 2.4rem !important;
  width: 2.4rem !important;
}
