// в этом файле описываются стили компонентов перезаписывает стандартные стили в соответсвии с темой

//THEMING
// mat-selection-list настройка отображения checkboxes
.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-accent);
  --mdc-checkbox-selected-hover-icon-color: var(--color-accent);
  --mdc-checkbox-selected-icon-color: var(--color-accent);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-accent);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

//настройка отображения отдельных xtr,jrcjd
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-accent);
  --mdc-checkbox-selected-hover-icon-color: var(--color-accent);
  --mdc-checkbox-selected-icon-color: var(--color-accent);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-accent);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

// галочка в select
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  //background-color: transparent !important;
}

//ngx-mat-select-search
.mat-select-search-input {
  background-color: var(--color-white) !important;
}

html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--color-accent);
  --mdc-switch-selected-handle-color: var(--color-accent);
  --mdc-switch-selected-hover-state-layer-color: var(--color-accent);
  --mdc-switch-selected-pressed-state-layer-color: var(--color-accent);
  --mdc-switch-selected-focus-handle-color: var(--color-accent);
  --mdc-switch-selected-hover-handle-color: var(--color-accent);
  --mdc-switch-selected-pressed-handle-color: var(--color-accent);
  --mdc-switch-selected-focus-track-color: var(--color-accent);
  --mdc-switch-selected-hover-track-color: var(--color-accent);
  --mdc-switch-selected-pressed-track-color: var(--color-accent);
  --mdc-switch-selected-track-color: var(--color-accent);
}


.mat-badge-accent .mat-badge-content {
  background-color: var(--color-accent);
  color: white;
}

.mat-badge-primary .mat-badge-content {
  background-color: var(--color-primary);
  color: white;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-accent);
  --mdc-radio-selected-hover-icon-color: var(--color-accent);
  --mdc-radio-selected-icon-color: var(--color-accent);
  --mdc-radio-selected-pressed-icon-color: var(--color-accent);
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: var(--color-accent);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

// mat-list c отключенным hideSingleSelection
.mdc-list-item.mdc-list-item--selected {
  background-color: var(--color-almost-white) !important;
}

// mat-list c отключенным hideSingleSelection
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--color-primary) !important;
  font-weight: bold;
}

//выделенная дата в mat-datepicker
.mat-calendar-body-selected {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

//mat tab
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--color-primary);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-primary);
  --mat-tab-header-active-ripple-color: var(--color-primary);
  --mat-tab-header-inactive-ripple-color: var(--color-primary);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-primary);
  --mat-tab-header-active-hover-label-text-color: var(--color-primary);
  --mat-tab-header-active-focus-indicator-color: var(--color-primary);
  --mat-tab-header-active-hover-indicator-color: var(--color-primary);
}

/* mat-form-field Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--color-primary) !important;
}

//mat-table theming
.mat-mdc-header-cell {
  font-weight: bold !important;
  color: var(--color-primary);

  border-top-width: 2px !important;
  border-top-color: var(--color-primary) !important;

  border-bottom-width: 2px !important;
  border-bottom-color: var(--color-primary) !important;
}

.mat-mdc-footer-cell {
  font-weight: bold !important;
  color: var(--color-primary);
  border-bottom-width: 2px !important;
  border-bottom-color: var(--color-primary) !important;

  border-top-width: 2px !important;
  border-top-color: var(--color-primary) !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 !important;
}
