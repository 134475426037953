.app-form-container {
  padding-top: 8px;
  display: flex;
  flex-direction: column;

  &.row {
    flex-direction: row;
  }
}

// почему-то с mat-form-field кнопки не хотят выравниваться по вертикали
// пока такое решение устанавливать для кнопки вручную margin
.mat-input-align-margin {
  margin-bottom: 22px;
}

.mat-mdc-form-field {
  padding-top: 8px !important;
}
