@import "variables";

.common-box-table {
  width: 100%;
  height: calc(100% - #{$height-header});
  overflow: auto;

  &.padding_eq_s {

    .table {

      th, td {
        padding: 2px;
        overflow-wrap: anywhere;
      }

      td.mat-mdc-cell:first-of-type {
        padding: 0;
      }
    }
  }

  .table {
    width: 100%;

    .header {
      height: 20px;
    }

    .padding-first-column {
      padding-left: 3px;
    }

    th, td {
      padding: 3px;
    }

    .buttons-action {
      padding-right: 0;
    }

    .column-width {
      width: 30%;
    }

    .not-visible {
      opacity: 0.5;
    }

    .title {
      padding-bottom: 0;
      font-weight: bold;
      border-bottom: none;
    }

    .filter {
      padding: 0 5px;

      .wrapper-input {
        width: 120px;
      }
    }

    .date-filter {
      padding: 0;

      .retranslator-date-filter {
        transform: translate(-5px, -8px);
      }
    }

    .icon-correct,
    .icon-remove,
    .icon-on {
      color: var(--color-green);
      overflow: hidden;
    }

    .icon-warn {
      color: var(--color-yellow);
    }

    .icon-error,
    .icon-off {
      color: var(--color-red);
      opacity: 1;
      overflow: hidden;
    }

    .highlight:hover {
      background-color: var(--color-light-opacity);
      cursor: pointer;

      &.without-pointer {
        background-color: var(--color-almost-white);
        cursor: initial;
      }
    }
  }
}

// units, users, template-set-list
.common-box-large-table {
  height: calc(100% - #{$height-filter-group + $height-mat-paginator});
  overflow: auto;

  .large-table {
    position: relative;
    width: 100%;
    white-space: nowrap; // when vertical scrolling with horizontal scrolling exclude filter overlapping header

    .header {
      height: 20px;
    }

    .title {
      padding: 0 5px;
      font-weight: bold;
      border-bottom: none;

      &.id {
        width: 60px;
      }
    }

    .filter {
      padding: 0 5px;

      .wrapper-input {

        &.id {
          width: 60px;
        }

        .suffix-spinner {
          position: absolute;
          top: 5px;
          right: 9px;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subtitle {
          margin: 0;
          font-size: 1.2rem;
        }
      }
    }

    .divider {
      outline: 1px solid var(--color-divider);
    }

    td.mat-mdc-cell {
      max-width: 200px;
      padding: 5px;
      font-family: $font-family;
      text-overflow: ellipsis;
      overflow-x: hidden;
      cursor: pointer;
    }

    .highlight:hover {
      background-color: var(--color-light-opacity);
      cursor: pointer;
    }
  }

  .icon-online {
    color: var(--color-green);
  }

  .icon-offline {
    color: var(--color-red);
  }

  .icon-not-valid {
    color: var(--color-map-basic);
  }
}
