@import "themes";

$min-width-basic: 320px;
$max-width-table: 350px;

$width-form-field: 250px;
$width-card: 635px;
$width-content-basic: 230px;

$height-header: 60px;
$height-mat-paginator: 56px;
$height-dialog-basic: 65vh;

$max-height-basic: 90vh;

$size-logo: 37px;
$size-logo-small: 25px;

$font-family: "Helvetica Neue", Helvetica, sans-serif;

$border-basic: 2px solid var(--color-primary);
$border-radius: 2px;

$variables: (
  --color-primary: var(--color-primary),
  --color-basic: var(--color-basic),
  --color-accent: var(--color-accent),
  --color-minor: var(--color-minor),
  --color-text: var(--color-text),
  --color-secondary: var(--color-secondary),
  --color-divider: var(--color-divider),
  --color-light-opacity: var(--color-light-opacity),
  --color-almost-white: var(--color-almost-white),
  --color-white: var(--color-white),
  --color-green: var(--color-green),
  --color-red: var(--color-red),
  --color-yellow: var(--color-yellow),
  --color-map-basic: var(--color-map-basic)
);
