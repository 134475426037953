@font-face {
  font-family: 'Logo';
  src: url('fonts/nasalization.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.logo-title {
  font-size: 1.8rem;
  font-family: Logo, $font-family;
  text-transform: uppercase;
  color: var(--color-white);
}

.logo-font {
  font-family: Logo, $font-family;
}
