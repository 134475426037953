.opacity10 {
  opacity: 10%;
}

.opacity20 {
  opacity: 20%;
}

.opacity40 {
  opacity: 40%;
}


.opacity50 {
  opacity: 50%;
}

.opacity100 {
  opacity: 50%;
}
